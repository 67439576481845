@import "../../../variables.scss";

.tour-crud-container {
  button, input[type="submit"] {
    color: white;
    border: none;
    outline: none;
    background-color: $primary-color;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .cancel {
    background-color: red;
    margin-left: 15px;
  }

  .tour-crud-upsert-form {
    margin-top: 15px;
  }

  .tour-card-remove-icon,
  .tour-card-edit-icon {
    position: absolute;
    top: 10px;
    left: 25px;
    padding: 3px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .tour-card-edit-icon {
    left: 60px;
  }
}