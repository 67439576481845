@import '../../../variables.scss';

.modal-list-user {
  padding: 30px !important;
  background-color: $primary-color;
  color: white;
  border-radius: 10px;

  h3 {
    text-decoration: underline;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .user-row {
    display: flex;
    margin-bottom: 10px;

    p {
      margin: 0px;
      margin-bottom: 2.5px;
    }

    img, .dummy-avatar {
      width: 50px;
      height: 50px;
      background-color: rgba(255,255,255,0.8);
      border-radius: 25px;
      margin-right: 10px;
    }
    img {
      object-fit: cover;
    }
  }
}