@import "../../../variables.scss";

.video-chat-popup-group {
  &__overlay {
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

  }

  &__maximize {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    height: 80vh;
    transform: translate(-50%, -50%);
    border: 2px solid $primary-color;
    background-color: white;

    .first-row {
      padding: 15px;
      text-align: right;
      // .minimize-btn {
      //   border: 1px solid $primary-color;
      //   color: $primary-color;
      //   outline: none;
      //   padding: 5px 25px;
      // }
      position: relative;

      span {
        display: 'inline-block';
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        color: $primary-color;
        font-weight: bold;
        font-size: 18px;
      }

      .proceed-to-tour {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(255,255,255,0.5);
        cursor: pointer;
      }

      background-color: $bg-color;
      .minimize-icon-btn {
        border: none;
        outline: none;
        background-color: transparent;

        &.disabled {
          opacity: 0;
        }
      }

    }
    
    .second-row {
      flex: 1;
      padding: 7.5px;

      .big-view {
        flex: 1;
        padding: 7.5px;
        display: flex;
        flex-direction: row;

        .camera-view {
          flex: 1;
          background-color: rgba(0,0,0,0.2);
          position: relative;

          video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            background-color: black;
            
          }
        }

        &.grid {
          flex-wrap: wrap;

          &.grid-9 .camera-view {
            width: 33.33%;
            height: 33.33%;
            flex: none;
          }

          &.grid-4 .camera-view {
            width: 50%;
            height: 50%;
            flex: none;
          }

          &.grid-16 .camera-view {
            width: 25%;
            height: 25%;
            flex: none;
          }
        }

        
      }

      .small-view {
        width: 150px;
        padding: 7.5px;
        display: flex;
        flex-direction: column;
        text-align: right;

        .camera-view {
          height: 100px;
          background-color: rgba(0,0,0,0.2);
          position: relative;

          video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            background-color: black;
            
          }
        }

        .dummy {
          flex: 1;
        }

        .leave-btn {
          border: 1px solid red;
          color: red;
          outline: none;
          padding: 5px 15px;
          font-weight: bold;

          &.disabled {
            background-color: gray;
            color: rgba(225,255,255,0.2);
            border: 1px solid gray;
          }
        }
      }
    }
  }

  &__minimize {
    position: fixed;
    top: 40px;
    right: 200px;
    left: auto;
    width: 150px;
    height: 150px;
    border: 2px solid $primary-color;
    background-color: white;
    z-index: 1000;
    border-radius: 100%;
    cursor: all-scroll;

    &.hide {
      transform: translateX(-9999px);
    }

    .maximize-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      cursor: pointer;
      z-index: 1000;
      background-color: $primary-color;
      padding: 3px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .drag-icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      // border-radius: 100%;
      bottom: 0px;
      left: 0px;
      cursor: pointer;
      z-index: 1000;
      background-color: $primary-color;
      // padding: 3px;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: black;
      border-radius: 100%;
    }
  }
}

.video-chat-popup-group__overlay.wrapper-new-minimized {
  background-color: transparent;
  display: none;
}

.video-chat-popup-group__overlay.video-chat-popup-group__maximize.new-minimized {
  background-color: #F5F5F5;
  width: 120px;
  height: calc( 100% - 100px );
  top: 74px;
  left: 25px;
  transform: none;
  border: none;
  margin-top: 25px;

  .first-row {
    display: none;
    background-color: transparent;
    padding: 0px;
    text-align: center;
    span {
      display: none;
    }
    .minimize-icon-btn {
      transform: translateX(-10px);
    }
  }
}