@import '../../variables.scss';

.signin-modal {
  
  .modal-dialog {
    max-width: 600px;
  
    .modal-content {
      background-color: $bg-color;
      .modal-body {
        padding: 20px 150px;  
        color: white;

        h1 {
          color: white;
        }
  
        .form-group {
          margin-bottom: 0.8rem;
          width: 100%;

          label {
            margin: 0;
            color: white;
            opacity: 0.8;
            font-size: 0.8rem;
          }
  
          input {
            background: transparent;
            border: none;
            outline: none !important;
            box-shadow: none;
            border-bottom: 1px solid white;
            border-radius: 0;
            padding: 0;
            color: white;
            height: 1.5rem;
          }
          
          ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
          }
        }      
  
        Button {
          background: white;
          width: 100%;
          color: $grey-color;
          margin-top: 0;
          border: 1rem;
          height: 40px;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.9rem;
        }
  
        a {
          text-align: center;
          font-size: 0.8rem;
          margin-top: 0.5rem;
          color: white;
          opacity: 0.8;
          cursor: pointer;
        }
  
        .signin-btn {
          height: 40px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.9rem;
          width: 100%;
          color: white;
  
          a {
            cursor: pointer;
            opacity: 1;
          }
        }

        @media (max-width: 991px){
          padding: 20px 50px;
        }
      }
    }
  }

  @media (max-width: 991px){
    .form-group {
      margin-bottom: 0.5rem;
    }
  }
}
