@import "../../../variables.scss";

.tour-item {
  .tour-image {
    position: relative;

    img {
      width: 200px;
      height: 150px;
    }

    .group-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
      z-index: 2;
    }

    .clear-tour-session {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 30px;
      height: 30px;
      z-index: 2;
      border-radius: 100%;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    button {
      border: none;
      outline: none;
      box-shadow: none;
      background: none;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      height: 100%;
      background: black;
      opacity: 0.3;
    }
  }

  .info {
    flex: 1;
    font-size: 0.8rem;
    line-height: 1;
    padding-left: 0.5rem;

    label {
      font-weight: bold;
      margin-right: 0.5rem;
    }

    .status {
      font-weight: bold;

      span.pending {
        color: orange;
      }

      span.connected, span.complete {
        color: green;
      }

      span.available {
        color: $primary-color;
      }

      span.unavailable {
        color: red;
      }
    }
  }

  @media (max-width: 1199px) {
    .info {
      font-size: 0.6rem;
    }

    .tour-image {
      width: 170px;
      height: 120px;

      img {
        width: 100%;
        height: 120px;
      }
    }
  }
  
  @media (max-width: 991px) {
    flex-direction: column;
    width: 95%;

    .tour-image {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: 160px;
      }
    }

    .info {
      padding-left: 0;
      padding-top: 1rem;      
    }
  }

}
