

.chat-and-switch-btn-panel-group {
  .voice-call img, .video-call img, .chat img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .video-call img {
    transform: translateY(-2px);

    &.active {
      width: 30px;
      height: 30px;
      transform: translateY(2px);
    }
  }
  .add-users img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .chat {
    position: relative;
    .dot-notification {
      position: absolute;
      width: 7px;
      height: 7px;
      background-color: red;
      border-radius: 3.5px;
      top: 2px;
      right: 8px;
    }
  }
  
}