.group-name-modal {
  padding: 30px !important;
  color: white;

  input.dark {
    width: 100%;
    background-color: transparent;
    color: white;
    outline: none;
    border: none;
    font-size: 30px;
    border-bottom: 1px solid white;

    &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::-webkit-input-placeholder {
      color: #4E4E4E;
      opacity: 0.5;
    }

    &.invite-emails {
      color: white;
      font-size: 16px;
      &::placeholder {
        color: black;
      }

      &.for-theatro::placeholder {
        color: white;
      }
    }
  }

  // ::placeholder {
  //   color: white;
  // } 

  h4 {
    font-weight: 300;
    margin-top: 10px;
    padding: 5px 0px;
  }

  .link {
    color: white;
    margin-bottom: 5px;
    padding: 5px;
    border-bottom: 1px solid white;
  }

  .btn-copy-link {
    float: right;
    background-color: white;
    color: black;
    outline: none;
    border: none;
    width: 150px;
    height: 30px;
    font-weight: 300;
    text-transform: capitalize;

    &:hover {
      text-decoration: underline;
      background-color: rgba(0,0,0,0.2);
      color: white;
    }
  }
}

.react-datetime-picker {
  width: 100%;
}