@import '../../variables.scss';

.enter-code-modal {
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      .modal-body {
        padding: 20px 150px;
          
        .form-group {
          display: flex;
          justify-content: space-between;
          flex-flow: row;

          .react-code-input {
            display: flex !important;
            justify-content: space-between;
          }

          .code-number {
            width: 20%;
          }

          label {
            margin: 0;
            color: white;
            opacity: 0.8;
          }
  
          input {
            background: transparent;
            border: none;
            outline: none !important;
            box-shadow: none;
            border-bottom: 1px solid white;
            border-radius: 0;
            padding: 0;
            color: white;
            text-align: center;
          }

          ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
          }
        }

        p {
          font-size: 0.9rem;
          width: 400px;
          margin-left: -60px;
          text-align: center;
          color: white;
          font-weight: 500;
          opacity: 0.7;
          margin-top: 1rem;
          margin-bottom: 2rem;
        }        
  
        Button {
          background: white;
          width: 100%;
          color: $grey-color;
          margin-top: 0;
          border: 1rem;
          height: 40px;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.9rem;
        }
  
        a {
          text-align: center;
          font-size: 0.8rem;
          margin-top: 0.5rem;
          color: white;
          opacity: 0.8;
          cursor: pointer;
        }
  
        .cancel-btn {
          height: 40px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.9rem;
          width: 100%;
          color: white;
  
          a {
            cursor: pointer;
            opacity: 1;
          }
        }
      }
    }
  }
}
