@import "../../../variables.scss";

.manage-user-container {
  .user-row {
    display: flex;
    flex-direction: row;
    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 20px;
    }

    padding-bottom: 10px;
    padding-top: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }

  
}

.filter-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  
}

.add-new-button, .user-upsert-form input[type=submit], .user-upsert-form button {
  color: white;
  border: none;
  outline: none;
  background-color: $primary-color;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 5px;
}
