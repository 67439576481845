@import '../../../variables.scss';

.user-panel-group {
  background-color: transparent;
  overflow-y: auto;
  .name {
    font-size: 1em;
    text-align: center;
    color: $primary-color;
  }
  p {
    font-size: 0.8em;
    text-align: center;
  }
  .role {
    font-weight: 500;
    text-transform: capitalize;
  }

  .avatar-video-panel {
    overflow-y: auto;
  }

  .avatar-video-container {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
    border-radius: 100%;
    video {
      height: 100%;
      width: 100%;
      border-radius: 100%;
      background-color: black;
    }

    &:empty {
      display: none;
    }
  }
}