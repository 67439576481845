@import '../../../variables.scss';

.group-user-add-name-modal {
  padding: 30px !important;
  background-color: white;
  border-radius: 10px;

  .dummy-nav-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0px !important;
    margin: 0px;
    border-radius: 0px;
    // display: flex;
    // justify-content: center !important;
    // align-items: center;
  }

  h4, p {
    text-align: center;
  }

  input {
    width: 100%;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: 4px;
    outline: none;
    padding: 10px;
    margin-top: 5px;
  }

  label {
    width: 100%;
  }

  .label {
    margin-bottom: 0px;
    text-align: left;
  }

  .avatar-frame {
    width: 110px;
    height: 110px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    margin-left: calc( (100% - 110px) / 2 );
    margin-top: 15px;

    &.middle-row {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      span {
        margin: 3px;
        font-size: 8px;
        cursor: pointer;
        &:hover {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .remove-avatar {
    font-size: 10px;
    cursor: pointer;
  }

  .btn-ok {
    margin-left: calc( (100% - 150px) / 2 );
    background-color: $primary-color;
    color: black;
    outline: none;
    border: none;
    width: 150px;
    height: 30px;
    font-weight: 300;
    text-transform: capitalize;
    float: right;
    transform: translateX(-25px);
  }
}