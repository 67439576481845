@import './variables.scss';

* {
  font-family: 'Montserrat', sans-serif;  
}
body {    
  color: #2E2D2C;
  background-color: #F5F5F5;
  overflow-x: hidden;
}

.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: $grey-color !important;
  border-color: $grey-color !important;
}

.btn-primary.focus, 
.btn-primary:focus {
  color: #fff !important;
  background-color: $grey-color !important;
  border-color: $grey-color !important;
  box-shadow: 0 0 0 0.2rem rgba(68,68,68,.5);
}

.btn-bugress-primary {
  border-radius: 0;
  background-color: $primary-color;
  color: white;
  outline: none;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.btn-bugress-outline {
  border-radius: 0;
  border: 2px solid $primary-color;
  outline: none;
  background-color: white;
  cursor: pointer;
  color: $primary-color;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  box-shadow: none !important;

  &:hover, &:focus, &:active {
    background-color: $primary-color !important;
    color: white !important;
    border-color: $primary-color !important;
  }
}

.btn-bugress-outline.btn-secondary {
  color: #A3A1A1;
  border-color: #A3A1A1;
  background-color: transparent;

  &:hover {
    border-color: $primary-color;
    color: $primary-color;

    a {
      color: white !important;
    }
  }
}

.btn-bugress-outline.btn-red {
  color: #FF0050;
  border-color: #FF0050;
}

.btn-disable {
  cursor: none !important;
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.bug-radio {
  display: inline-flex;
  line-height: 27px;
}

.bug-radio .custom-control {
  padding: 0;
}

.bug-radio .custom-control-label {
  position: relative;
  padding-left: 47px;
  line-height: 27px;
}

.custom-radio .custom-control-label::before {
  width: 27px;
  height: 27px;
  top: 0;
  border: 1px solid #707070;    
  background-color: transparent !important;  
  left: 0
}

.bug-radio.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-image: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 13px;
  height: 13px;
  background-color: $primary-color;  
  background-image: none;
  border-radius: 6.5px;
}

.modal-backdrop {
  background-color: white;
  &.show {
    opacity: 0.75;
  }
}

.form-group {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;

  label, input[type=text], input[type=password], input[type=email], input[type=tel] {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  input[type=text] + label,
  input[type=email] + label,
  input[type=password] + label,
  input[type=tel] + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 1.525rem) scale(1.2);
  }


  input:not(:placeholder-shown) + label,
  input[type=text]:focus + label,
  input[type=email]:focus + label,
  input[type=password]:focus + label,
  input[type=tel]:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
  }

  input::-webkit-input-placeholder, input::placeholder {
    color: transparent !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $primary-color inset !important;
    -webkit-text-fill-color: white;
  }
}

.alert {
  position: fixed;
  right: 10px;
  top: 30px;
  margin: 0;
  font-size: 0.8rem;
  font-weight: bold;
  z-index: 1000;
}

.modal-dialog {
  max-width: 768px;

  .modal-content {
    background-color: $bg-color;
    border: none;
    border-radius: 0.8rem;

    .modal-header {
      border-bottom: none;
      padding-top: 2rem;
      padding-bottom: 0;
      text-align: center;

      h1 {
        width: 100%;
        font-size: 1.8rem;
        font-weight: 700;
        color: white;
      }

      h2 {
        width: 100%;
        color: white;
        font-weight: 700;
        font-size: 1.7rem;
      }
    }
    
    .modal-body {
      padding: 10px 200px;

      h1 {
        color: white;
        text-align: center;
        font-size: 1.7rem;

        span.user-type {
          text-transform: capitalize;
        }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {  
  background: linear-gradient(to left, transparent, transparent 5px, #A3A1A1 6px, transparent, 7px, transparent 100%);  
  background-size: 100% auto;
  background-repeat: repeat-y;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  height: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color; 
}

::-webkit-scrollbar-button:start:decrement {
  display: none;  
}

::-webkit-scrollbar-button:end:increment {
  display: none;
}

.last-chat::-webkit-scrollbar-thumb {
  background: #A3A1A1;   
}

.last-chat::-webkit-scrollbar-thumb:hover {
  background: #A3A1A1; 
}

@media (min-width: 768px){
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1  {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: inherit;
    flex: inherit;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: inherit;
    flex: inherit;
  }
}

/**
 *  The following is not really needed in this case
 *  Only to demonstrate the usage of @media for large screens
 */    
@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    max-width: inherit;
    flex: inherit;
  }
}

@media (max-width: 991px) {
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      .modal-header {
        padding-top: 1.5rem;

        h1 {
          font-size: 1.5rem;
        }

        h2 {
          font-size: 1.4rem;
        }
      }

      .modal-body {
        padding: 10px 100px;

        h1 {
          font-size: 1.4rem;
        }
      }
    }
  }
}

@import "./sharedComponents/NavMenu/styles.scss";
@import "./sharedComponents/RegisterModal/styles.scss";
@import "./sharedComponents/SigninModal/styles.scss";
@import "./sharedComponents/EnterCodeModal/styles.scss";
@import "./sharedComponents/ThankyouModal/styles.scss";
@import "./sharedComponents/ChooseTourModal/styles.scss";
@import "./sharedComponents/VoiceChattingModal/styles.scss";
@import "./components/TourPlay/styles.scss";
@import "./components/TourPlay/ChattingPanel/styles.scss";
@import "./components/TourPlay/MainPanel/styles.scss";
@import "./components/Homepage/styles.scss";
@import "./components/Dashboard/styles.scss";
@import "./components/VirtualTour/styles.scss";