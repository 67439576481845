@import '../../../variables.scss';

.main-broker-disconnect-modal {
  padding: 30px !important;
  background-color: white;
  color: black;
  border-radius: 10px;

  h3, a {
    color: $primary-color;
  }
}