@import '../../../variables.scss';

.left-panel {
  flex: 1 0 120px;  

  .user-img {
    height: 180px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .control-div {
    .call-btn {
      cursor: pointer;
      border-radius: 100%;
      display: inline-block;
      height: 50px;

      &.calling {
        animation: play 1.5s ease infinite;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;

        @keyframes play {
          0% {
              transform: scale(1);
          }
          15% {
              box-shadow: 0 0 0 3px rgba(0, 175, 211, 0.2);
          }
          25% {
              box-shadow: 0 0 0 5px rgba(0, 175, 211, 0.2), 0 0 0 5px rgba(0, 175, 211, 0.1);
          }
          25% {
              box-shadow: 0 0 0 7px rgba(0, 175, 211, 0.2), 0 0 0 15px rgba(0, 175, 211, 0.1);
          }
        }
      }
    }
    h5 {
      font-size: 1.6em;
      font-weight: 700;
    }
    p {
      font-size: 1em;
    }
    img {      
      width: 30px;
    }
  }

  

  @media (max-width: 1199px) {
    flex: 1 0 240px;

    .user-img {
      height: 80px;
    }

    .control-div {
      h5 {
        font-size: 1rem;
      }

      p {
        font-size: 0.7rem;
      }

      img {
        width: 24px;
      }
    }

    .chatting-info {
      .chatting-history {
        .chating-text-box {
          h5 {
            font-size: 0.8rem;
          }
        }
      }

      .last-chat {
        textarea{
          font-size: 12px;
    
          &::placeholder {
            font-size: 12px;
          }  
        }
      }
    }
  }
}

.chatting-info {
  flex: 1 1 auto;
  overflow: hidden;
  border-top: 2px solid #F5F5F5;
  position: relative;
  background-color: white;

  .chatting-history {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 0;
    position: relative;
    display: block;
    scrollbar-width: thin;
    scrollbar-color: $primary-color #eee;

    .message-wrapper {
      padding: 0 0 120px 0;

      .chating-text-box {
        font-size: 0.7em;
        border-bottom: 2px solid #F5F5F5;
        
        &:last-child {
          border-bottom: 0;
        }

        h5 {
          font-weight: 700;
          font-size: 1rem;
        }

        span {
          display: block;
          width: 100%;
        }
      }
    }     
  }
  
  .last-chat {
    position: absolute;
    bottom: 0.5rem;
    left: 1.5rem;
    width: calc(100% - 3.5rem);
    background: white;

    textarea{
      width: 100%;
      height: 70px;
      background: #F5F5F5;
      border: 1px solid #F5F5F5;
      outline-style: none;
      color: #2E2D2C;
      flex: 0 0 100px;
      overflow-y: auto;
      font-size: 14px;
      padding: 9px 8px;  
      resize: none;
      font-weight: 600;

      &::placeholder {
        font-size: 14px;
        font-weight: 600;
        color: #2E2D2C;
      }  
    }
  }
}

@media (max-width: 1199px) {

  .chatting-info {
    .chatting-history {
      .chating-text-box {
        h5 {
          font-size: 0.8rem;
        }
      }
    }

    .last-chat {
      textarea{
        font-size: 12px;
  
        &::placeholder {
          font-size: 12px;
        }  
      }
    }
  }
}

.chat-slide-in-panel {
  position: fixed;
  top: 205px;
  right: -320px;
  bottom: 100px;
  width: 320px;
  transition: 1s;
  z-index: 10000;

  &.show {
    transition: 1s;
    right: 0;
  }
  &.hide {
    transition: 1s;
    right: -320px;
  }
}