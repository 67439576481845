@import '../../../variables.scss';

.stop-session-modal {
  padding: 30px !important;
  background-color: white;
  color: black;
  border-radius: 10px;

  h3, a {
    color: $primary-color;
  }

  .middle-row {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      border: none;
      outline: none;
      color: black;
      text-decoration: underline;
      width: 20% !important;
      font-weight: 400;
  
      &.confirm-btn {
        background-color: red;
        color: white;
        text-decoration: none;
      }
    }
  }

  
}